import React, { useState } from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'; // Import MUI 5 components
import { useDeleteBannedTerm } from '@hooks/useBannedTerms';
import { useDeleteAllowedTerm } from '@hooks/useAllowedTerms';

interface DeleteBannedTermProps {
  id: number;
  callbackFn?: () => void;
  isBanned?: boolean;
}

const DeleteBannedTerm = ({
  id,
  callbackFn = () => {},
  isBanned = true
}: DeleteBannedTermProps) => {
  const { mutate: deleteBannedTerm } = useDeleteBannedTerm();
  const { mutate: deleteAllowedTerm } = useDeleteAllowedTerm();

  const deleteFunc = isBanned ? deleteBannedTerm : deleteAllowedTerm;

  // New state variable to track delete confirmation modal
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [selectedDelete, setSelectedDelete] = useState(null);

  // Function to open delete confirmation modal
  const handleOpenDeleteConfirmationModal = (id: number) => {
    setSelectedDelete(id);
    setIsDeleteConfirmationModalOpen(true);
  };

  // Function to close delete confirmation modal
  const handleCloseDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
  };

  // Function to handle delete button click
  const handleDeleteButtonClick = (id: number) => {
    handleOpenDeleteConfirmationModal(id);
  };

  // Function to handle confirmed delete
  const handleConfirmedDelete = () => {
    deleteFunc({
      id: selectedDelete
    }); // Proceed with delete
    setSelectedDelete(null); // Reset the selected delete
    handleCloseDeleteConfirmationModal(); // Close the delete confirmation modal
    callbackFn(); // Callback function to refresh the banned terms list
  };

  return (
    <Box>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => handleDeleteButtonClick(id)}
      >
        Delete
      </Button>
      <Dialog
        open={isDeleteConfirmationModalOpen}
        onClose={handleCloseDeleteConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete this ${
              isBanned ? 'Blocked' : 'Allowed'
            } term?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmationModal}>Cancel</Button>
          <Button onClick={handleConfirmedDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteBannedTerm;
