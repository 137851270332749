import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { DealPostType, DiaperDealType } from '../types';

interface LatestDealsResponseType {
  latestDeals: DealPostType[];
  tag: string;
}

export function useGetLatestDeals(): UseQueryResult<LatestDealsResponseType> {
  const queryDealLists = async (): Promise<LatestDealsResponseType> => {
    const result = await axios.get(`/api/getLatestDeals`);
    return result.data;
  };

  return useQuery<LatestDealsResponseType, Error>(
    `latest-deals-db`,
    () => queryDealLists(),
    {
      // refetch errr 3 mins
      refetchInterval: 1000 * 60 * 3,
      onError: () => {
        console.error('Something went wrong while fetching the deals');
      }
    }
  );
}

export function useGetBrandFilters(): UseQueryResult<string[]> {
  const queryDealLists = async (): Promise<string[]> => {
    const result = await axios.get(`/api/brand-filters`);
    return result.data;
  };

  return useQuery<string[], Error>(
    `latest-brand-filters`,
    () => queryDealLists(),
    {
      onError: () => {
        console.error('Something went wrong while fetching the brand filters');
      }
    }
  );
}

const queryCouponDeals = async (promoCode: string): Promise<DealPostType[]> => {
  const result = await axios.get(
    `/api/get-coupon-deals?promoCode=${promoCode}`
  );
  return result.data;
};

export function useGetCouponDealsByCode(
  code: string
): UseQueryResult<DealPostType[]> {
  return useQuery<DealPostType[], Error>(
    `coupon-deals-${code}`,
    () => queryCouponDeals(code),
    {
      onError: () => {
        console.error('Something went wrong while fetching the coupon deals');
      }
    }
  );
}

export function useGetDiaperDeals(): UseQueryResult<DiaperDealType[]> {
  const queryFn = async (): Promise<DiaperDealType[]> => {
    const result = await axios.get('/api/dashboard/getDiaperDeals');
    return result.data?.diaperDeals || [];
  };

  return useQuery<DiaperDealType[], Error>(`diaper-deals-db`, () => queryFn(), {
    // refetch errr 5 mins
    refetchInterval: 1000 * 60 * 5,
    onError: () => {
      console.error('Something went wrong while fetching the deals');
    }
  });
}

export function useAddDiaperDeal() {
  interface RequestData {
    ASIN: string;
    size: string;
  }

  const mutationFn = async ({ ASIN, size }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/addDiaperDeal`, {
      ASIN,
      size
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('diaper-deals-db');
    },
    onError: (...args) => {
      console.error('error adding diaper deal', args);
    }
  });
}

export function useUpdateDealsByBrand() {
  interface RequestData {
    brand: string;
  }

  const mutationFn = async ({
    brand
  }: RequestData): Promise<{
    rechecking: 0;
  }> => {
    const retData = await axios.post(`/api/dashboard/recheck-deals-by-brand`, {
      brand
    });

    return retData.data;
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('latest-deals-db');
    },
    onError: (...args) => {
      console.error('error updating coupon by brand', args);
    }
  });
}

interface MetaType {
  pictureFile: File | null;
  ASIN: string;
  price: string;
  store: string;
}

export function useUploadNewCompareImage() {
  const mutationFn = async ({
    pictureFile,
    price,
    ASIN,
    store
  }: MetaType): Promise<any> => {
    const formData = new FormData();

    if (pictureFile) {
      formData.append('file', pictureFile, pictureFile.name);
      formData.append('price', price);
      formData.append('store', store);
      formData.append('ASIN', ASIN);

      const response = await axios.post(
        '/api/dashboard/compare-to/upload-compare-details',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response.data;
    }

    throw new Error('No file provided');
  };

  return useMutation(mutationFn, {
    onSuccess: (_data, { ASIN }) => {
      queryClient.refetchQueries(`deal-summary-${ASIN}`);
      queryClient.refetchQueries(`deal-summary-admin-${ASIN}`);
      queryClient.refetchQueries('latest-deals-db');
    },
    onError: (...args) => {
      console.error('error uploading compare image', args);
    }
  });
}
