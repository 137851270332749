/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { BannedTerm } from '../types';
import { useSnackbar } from './useSnackbar';

export function useGetBannedTerms(): UseQueryResult<BannedTerm[]> {
  const queryFn = async (): Promise<BannedTerm[]> => {
    const result = await axios.get(`/api/dashboard/banned-terms/get`);
    return result.data;
  };

  return useQuery<BannedTerm[], Error>(`banned-terms`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the banned-terms');
    }
  });
}

export function useDeleteBannedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestDeleteData {
    id: number;
  }

  const mutationFn = async ({ id }: RequestDeleteData): Promise<null> => {
    return axios.delete(`/api/dashboard/banned-terms/delete/${id}`);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`banned-terms`);
      showMessage('Banned term deleted');
    },
    onError: () => {
      console.error('error deleting banned terms');
    }
  });
}

export function useUpdateBannedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    id: number;
    term: string;
    type: string;
    exact: boolean;
  }

  const mutationFn = async ({
    exact,
    term,
    type,
    id
  }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/banned-terms/update/${id}`, {
      exact,
      type,
      term
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`banned-terms`);
      showMessage('Banned term updated');
    },
    onError: () => {
      console.error('error updating banned term');
    }
  });
}

export function useAddBannedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    term: string;
    type: string;
    exact: boolean;
  }

  const mutationFn = async ({
    exact,
    term,
    type
  }: RequestData): Promise<{
    data: {
      updatedRecords: number;
    };
  }> => {
    return axios.post(`/api/dashboard/banned-terms/add`, {
      exact,
      type,
      term
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      const { updatedRecords } = data?.data;
      queryClient.refetchQueries(`banned-terms`);
      queryClient.refetchQueries(`unmapped-browse-nodes`);
      showMessage('Banned term added');
      if (updatedRecords > 0) {
        showMessage(
          `${updatedRecords} ${
            updatedRecords === 1 ? 'record' : 'records'
          } updated`
        );
      }
    },
    onError: () => {
      console.error('error adding banned term');
    }
  });
}
