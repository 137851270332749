export default [
  {
    label: 'Gift card',
    value: 'GIFT_CARD'
  },
  {
    label: 'Deal of the day',
    value: 'DOTD'
  },
  {
    label: 'Post text',
    value: 'BANNED_POST_TEXT'
  },
  {
    label: 'IP Address',
    value: 'BANNED_IP_ADDRESS'
  },
  {
    label: 'Post image',
    value: 'BANNED_POST_IMAGE'
  },
  {
    label: 'Email domain',
    value: 'BANNED_EMAIL_DOMAIN'
  },
  {
    label: 'Banned extra deal',
    value: 'BANNED_EXTRA_DEAL'
  },
  {
    label: 'Email TLD',
    value: 'BANNED_EMAIL_TLD'
  },
  {
    label: 'Banned auto add brand',
    value: 'BANNED_AUTO_ADD_BRANDS'
  }
];
