/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useQuery, UseQueryResult, useMutation } from 'react-query';
import queryClient from '../queryClient';
import { AllowedTerm } from '../types';
import { useSnackbar } from './useSnackbar';

export function useGetAllowedTerms(): UseQueryResult<AllowedTerm[]> {
  const queryFn = async (): Promise<AllowedTerm[]> => {
    const result = await axios.get(`/api/dashboard/allowed-terms/get`);
    return result.data;
  };

  return useQuery<AllowedTerm[], Error>(`allowed-terms`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the allowed terms');
    }
  });
}

export function useDeleteAllowedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestDeleteData {
    id: number;
  }

  const mutationFn = async ({ id }: RequestDeleteData): Promise<null> => {
    return axios.delete(`/api/dashboard/allowed-terms/delete/${id}`);
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`allowed-terms`);
      showMessage('allowed term deleted');
    },
    onError: () => {
      console.error('error deleting allowed terms');
    }
  });
}

export function useUpdateAllowedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    id: number;
    term: string;
    type: string;
    exact: boolean;
  }

  const mutationFn = async ({
    exact,
    term,
    type,
    id
  }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/allowed-terms/update/${id}`, {
      exact,
      type,
      term
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries(`allowed-terms`);
      showMessage('allowed term updated');
    },
    onError: () => {
      console.error('error updating allowed term');
    }
  });
}

export function useAddAllowedTerm() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    term: string;
    type: string;
    exact: boolean;
  }

  const mutationFn = async ({
    exact,
    term,
    type
  }: RequestData): Promise<{
    data: {
      updatedRecords: number;
    };
  }> => {
    return axios.post(`/api/dashboard/allowed-terms/add`, {
      exact,
      type,
      term
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      const { updatedRecords } = data?.data;
      queryClient.refetchQueries(`allowed-terms`);
      showMessage('Allowed term added');
      if (updatedRecords > 0) {
        showMessage(
          `${updatedRecords} ${
            updatedRecords === 1 ? 'record' : 'records'
          } updated`
        );
      }
    },
    onError: () => {
      console.error('error adding allowed term');
    }
  });
}
