import bannedTermTypes from '@utils/constants/bannedTermTypes';
import allowedTermTypes from '@utils/constants/allowedTermTypes';
import { AllowedTerm } from '@api/database/models/allowedTerm';
import { BannedTerm } from '@types';

export interface BannedTermCreate extends Pick<BannedTerm, 'term' | 'type'> {
  exact?: boolean;
}

export const getExistingTermFromTerms = (
  terms: BannedTerm[] | AllowedTerm[],
  newTermCreate?: BannedTermCreate | null
) => {
  if (!terms || terms.length === 0 || !newTermCreate) {
    return null;
  }
  return (
    terms.find(
      (term) =>
        term.term === newTermCreate?.term && term.type === newTermCreate?.type
    ) || null
  );
};

export const getTypeLabel = (type: string, isBanned: boolean) => {
  let label = 'Term';
  if (!type) {
    return label;
  }

  const termTypes = isBanned ? bannedTermTypes : allowedTermTypes;

  termTypes.forEach((bannedTermType) => {
    if (bannedTermType.value === type) {
      label = bannedTermType.label;
    }
  });

  return label;
};
