/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';

export const truncateTitle = (input: string, maxLength = 170): string => {
  if (input.length <= maxLength) {
    return input;
  }

  return `${input.substring(0, maxLength)}...`;
};

const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const parseSize = (input: string): string => {
  // First trim to remove leading and trailing spaces to avoid affecting replacements
  let formattedString = input.trim();
  // Replace "|" with ", " and ensure no spaces around pipes before replacing
  formattedString = formattedString.replace(/\s*\|\s*/g, ', ');
  // Add a space before any number that's directly followed by a word (without space in between)
  formattedString = formattedString.replace(/(\d)([A-Za-z])/g, '$1 $2');
  // Remove any redundant spaces (multiple spaces become one)
  formattedString = formattedString.replace(/\s+/g, ' ');
  return formattedString;
};

const removeSingleQuotes = (input: string): string => {
  if (
    input.length >= 2 &&
    input.charAt(0) === "'" &&
    input.charAt(input.length - 1) === "'"
  ) {
    return input.slice(1, -1);
  }
  return input;
};

const replaceOneCount = (input: string): string => {
  // turn ', Pack of 1' into '' (end of line only)
  let regex = /, Pack of 1$/i;
  let title = input.replace(regex, '');

  // turn ' (pack of 1)' into ''
  title = title.replace(/(\s*)?(\()pack of 1(\))/i, '');

  // turn ', Pack of 1' into '' (end of line only)
  // turn ', Pack of 1' into '' (end of line only)
  regex = /, Pack of 1$/i;
  title = title.replace(regex, '');
  // turn ' (pack of 1)' into ''

  // turn ' 1 count' into ''
  title = title.replace(' 1 count', '');
  // turn ' 1 Count' into ''
  title = title.replace(' 1 Count', '');

  // remove pack of 1 in parens
  title = title.replace(' (Pack of 1)', '');
  title = title.replace(/, 1 Pack$/, '');
  title = title.replace(/ 1 Pack$/, '');

  return title;
};

export const parseTitle = (str: string): string => {
  // ensure string
  let title = `${str}`;
  const regex = /test/i;

  // remove packaging may vary
  title = title.replace(
    /(\()?(\[)?(\s*)?packaging may va(r)?(y)?(\s*)?(\])?(\))?/im,
    ''
  );

  title = removeSingleQuotes(title);

  // remove colors may vary
  title = title.replace(/(\s*,?\s*(\(|,)?\s*Colors May Vary\s*(\)|,)?)/gi, '');

  title = title.replace(/\\'/g, "'");

  title = title.replace(/\\"/g, '"');

  // remove new packaging
  title = title.replace(/\(new packaging\)/im, '');

  // remove Package May Vary
  title = title.replace(
    /(\()?(\[)?(\s*)?Package may va(r)?(y)?(\s*)?(\])?(\))?/im,
    ''
  );

  // remove , One Size,
  title = title.replace(/, One Size,/i, '');

  // this will get rid of those extra commas and spaces
  // title = title.replace(/,\s*/, '');
  // title = title.replace(/!\s+!/, '');

  title = title.replace('â€“', '-');

  // fix comma grammar
  title = replaceAll(title, ' ,', ',');

  // turn ' | ' into ' ,'
  title = title.replace(' | ', ', ');

  // turn '( ' into '('
  title = title.replace('( ', '(');

  // turn ' )' into ')'
  title = title.replace(' )', ')');

  title = replaceOneCount(title);

  // turn ' ,' into ','
  title = title.replace(' ,', ',');

  // turn ' | ' into ', '
  title = title.replace(' | ', ', ');

  title = title.replace('&amp;', '&');

  title = title.replace(/[ ,;:/-]*$/, '');

  // remove "| " and replace with ", "
  title = title.replace(/(\S)\|\s/g, '$1, ');

  title = title.replace(/â€“/g, '—');

  // remove ", One Size" from the end of a title string
  title = title.replace(/, One Size$/i, '');

  return (
    title
      .replace(/\s+/g, ' ') // Collapses multiple spaces into a single space
      .replace(/\s*,\s*/g, ', ') // Ensures there is one space after commas, none before
      // .replace(/\s*-\s*/g, ' - ')  // Ensures there is one space on each side of hyphens
      .trim()
  ); // Removes any leading and trailing spaces
};
